<template>
  <div class="register">
    <div class="back" @click="$router.go(-1)">
      <img class="icon" src="../../assets/images/login/ic_redeem_return.webp" />
    </div>
    <img class="logo" src="../../assets/images/login/ic_log_in_logo.webp" />
    <div>绑定手机号</div>
    <div class="inputArea">
      <login-input type="text" icon="ic_runningpoints_mine.webp" placeholder="请输入手机号" v-model="userMobile" />
      <div class="flex-sb-c">
        <login-input type="password" icon="ic_runningpoints_verificationcode.png" placeholder="请输入验证码" v-model="verificationCode" />
        <button class="innerBtn" @click="getCode()">获取验证码</button>
      </div>

      <login-input type="password" icon="ic_runningpoints_password.webp" placeholder="请输入密码" v-model="password" />
      <login-input type="password" icon="ic_runningpoints_password.webp" placeholder="请再次输入密码" v-model="checkPassword" />
    </div>
    <button class="btn oval mb_1rem" @click="goRegister()">注册</button>
  </div>
</template>

<script>
import LoginInput from "@/components/LoginInput";
import { Message } from "element-ui";
import { mapState, mapActions } from "vuex";
import { v4 as uuidv4 } from "uuid";
import cookie from "js-cookie";
import * as api from "@/api/message";
import tim from "@/utils/tim";

export default {
  name: "register",
  components: {
    LoginInput,
  },
  data() {
    return {
      userMobile: "",
      password: "",
      checkPassword: "",
      verificationCode: "",
    };
  },
  computed: {
    // ...mapState("register", ["registerData"]),
    ...mapState("register"),
    title() {
      return this.$route.meta.name;
    },
  },
  // watch: {
  //   registerData(e) {
  //     if (e.code == "ok") {
  //       this.$router.push("/login");
  //     }
  //   },
  // },
  methods: {
    ...mapActions("register", ["postRegister"]),
    ...mapActions("sms", ["getSmsRegisterCode"]),
    ...mapActions("auth", ["login"]),
    ...mapActions("message", ["getSignalData"]),

    back() {
      this.$router.go(-1);
    },

    async goRegister() {
      console.log("goRegister");
      if (this.userMobile.length != 11) {
        Message({
          message: "请输入11位手机号",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      } else if (this.verificationCode.length != 6) {
        Message({
          message: "短信验证码为6位数的数字",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      } else if (this.checkPassword != this.password) {
        Message({
          message: "再次输入密码错误",
          iconClass: "x",
          center: true,
          customClass: "error_message",
        });
      } else {
        const deviceNo = localStorage.getItem("deviceNo") || uuidv4();
        localStorage.setItem("deviceNo", deviceNo);

        let data = {
          userMobile: this.userMobile,
          password: this.password,
          verifyCode: this.verificationCode,
          deviceNo,
          device: 2,
        };
        console.log("註冊");
        await this.postRegister(data);
        console.log("註冊成功");

        try {
          let loginData = {
            userMobile: this.userMobile,
            password: this.password,
            deviceNo,
            device: 2,
          };
          const res = await this.login(loginData);
          console.log(res);
          if (res.code === "ok") {
            if (res.data.token != "") {
              const token = res.data.token;
              cookie.set("token", token);
              const signalData = await this.getSignalData({ signalNo: token });
              const userID = signalData.data.id;
              const userSig = signalData.data.genSig;

              const cookieUserIdObj = Object.assign({}, cookie.get("userId"));
              const cookieUserId = Object.values(cookieUserIdObj)
                .join("")
                .split("_")
                .pop();
              if (cookieUserId) await api.kickUserTim(cookieUserId);
              cookie.set("userId", userID);
              tim.init({ userID: userID, userSig });
            }
          }
        } catch (error) {
          console.error("Error:", error);
        }
      }
    },
    getCode() {
      let data = {
        userMobile: this.userMobile,
      };
      this.getSmsRegisterCode(data);
    },
    callMessage() {},
  },
  created() {},
};
</script>

<style lang="scss" scoped>
.register {
  background-image: url("../../assets/images/login/ic_log_in_bg.png");
  background-size: 100% auto;
  background-repeat: no-repeat;
  padding: 1.2em 1.2em 1.2rem 1.2em;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  overflow-y: scroll;
  @media (min-width: 480px) {
    background-image: unset;
    padding: 1.2em 7.2em 1.2rem 7.2em;
  }
  .back {
    width: 100%;
    flex: 8em 0 0;
    text-align: left;

    .icon {
      width: 2rem;
      height: auto;
    }
  }

  .logo {
    width: 7em;
    height: auto;
  }

  .inputArea {
    width: 100%;
    padding-top: 20%;
    margin-bottom: 1rem;
    @media (min-width: 480px) {
      padding-top: 0%;
      margin-bottom: 0rem;
    }
  }

  .innerBtn {
    flex: 6.5rem 0 0;
    margin-left: 0.5rem;
    border: none;
    border-radius: 5px;
    height: 100%;
    color: var(--orange);
    font-size: 1.1rem;
    font-weight: 600;

    &:active {
      background-color: var(--pink);
    }
  }

  .registerBtn {
    width: 100%;
    color: var(--black);
    font-size: 0.8rem;
  }

  .dialog_ {
    &header {
      font-size: 0.9em;
    }

    &title {
      font-size: 1em;
      font-weight: 600;
    }

    &msg {
      font-size: 0.8em;
    }
  }
}
</style>
